// src/pages/ActualitesPage.js
import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { fetchAllPosts } from '../utils/api'; // Appel API pour tous les articles
import Article from '../components/Article'; // Utilisation du composant Article

function ActualitesPage() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchAllPosts()
      .then(data => setPosts(data))
      .catch(error => console.error('Erreur lors de la récupération des articles:', error));
  }, []);

  return (
    <div>
      <h1>Actualités</h1>
      <div className="articles-list">
        {posts.map(post => (
          <Link to={`/post/${post.id}`} key={post.id}>
            <Article
              title={post.title.rendered}
              excerpt={post.excerpt.rendered}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ActualitesPage;
