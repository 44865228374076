// src/pages/ArticlePage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Pour récupérer l'ID de l'article
import { fetchPostById } from '../utils/api';  // Assurez-vous d'avoir cette fonction qui appelle un article par ID
import './ArticlePage.css'; // Styles spécifiques pour cette page

const ArticlePage = () => {
    const { id } = useParams(); // Récupère l'ID de l'article dans l'URL
    const [post, setPost] = useState(null); // Stocke les données de l'article
    const [loading, setLoading] = useState(true); // Gère l'état de chargement

    useEffect(() => {
        // Appel API pour récupérer l'article par ID
        fetchPostById(id)
            .then(data => {
                setPost(data); // Stocke l'article dans l'état
                setLoading(false); // Arrête l'état de chargement
            })
            .catch(error => {
                console.error('Erreur lors de la récupération de l\'article:', error);
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!post) {
        return <div>Article introuvable</div>;
    }

    return (
        <article className="article-page">
            {post._embedded && post._embedded['wp:featuredmedia'] && (
                <img
                    src={post._embedded['wp:featuredmedia'][0].source_url}
                    alt={post.title.rendered}
                    className="article-image"
                />
            )}
            <h1 className="article-title">{post.title.rendered}</h1>
            <div className="article-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </article>
    );
};

export default ArticlePage;
