import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ActualitesPage from './pages/ActualitesPage';
import ArticlePage from './pages/ArticlePage';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/actualites" element={<ActualitesPage />} />
          <Route path="/post/:id" element={<ArticlePage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
