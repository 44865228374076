const BASE_URL = 'https://champions.fabiogafforelli.fr/wp-json/wp/v2';

// Récupérer les 3 articles les plus récents
export async function fetchRecentPosts() {
  const response = await fetch(`${BASE_URL}/posts?per_page=3`);
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des articles récents');
  }
  return await response.json();
}

// Récupérer tous les articles
export async function fetchAllPosts() {
  const response = await fetch(`${BASE_URL}/posts`);
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des actualités');
  }
  return await response.json();
}

// Récupérer un article spécifique par ID


export const fetchPostById = async (id) => {
  const response = await fetch(`https://champions.fabiogafforelli.fr/wp-json/wp/v2/posts/${id}?_embed`);
  if (!response.ok) {
      throw new Error('Erreur lors de la récupération de l\'article');
  }
  const data = await response.json();
  return data;
};