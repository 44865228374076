// src/components/Article.js
import React from 'react';
import './Article.css'; // Assurez-vous d'importer le CSS

const Article = ({ title, excerpt, content, image }) => {
    return (
        <article className="article">
            {image && <img src={image} alt={title} className="article-image" />}
            <h1 className="article-title">{title}</h1> {/* Titre principal */}
            <div className="article-excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
            <div className="article-content" dangerouslySetInnerHTML={{ __html: content }} />
        </article>
    );
};

export default Article;
