import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchRecentPosts } from '../utils/api'; // Appel API pour les articles récents

function HomePage() {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    fetchRecentPosts()
      .then(data => setRecentPosts(data))
      .catch(error => console.error('Erreur lors de la récupération des articles récents:', error));
  }, []);

  return (
    <div>
      <h1>Bienvenue sur le site element wasp</h1>
      <h2>Articles récents</h2>
      <ul>
        {recentPosts.map(post => (
          <li key={post.id}>
            <h3><Link to={`/post/${post.id}`}>{post.title.rendered}</Link></h3>
            <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HomePage;
